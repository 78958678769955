<template>
  <div class="container-admin">
    <nav class="d-flex justify-start pa-5">
      <addTeacher />
    </nav>
    <div class="temp-list d-flex justify-center">
      <v-card class="temp-table ma-5">
        <v-card-title>
          Daftar Widyaiswara
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div v-if="!$apollo.queries.scopeTeacher.loading">
          <v-data-table
            :headers="headers"
            :loading="loadTable"
            :items="scopeTeacher.data"
            :search="search"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
          >
            <div v-if="$apollo.queries.scopeTeacher.loading">Loading ...</div>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-space-around">
                <v-btn fab dark small color="cyan">
                  <v-icon dark small @click="editPage(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="red"
                  @click.prevent="deleteConfirm(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="paginate"></v-pagination>
          </div>
        </div>
      </v-card>
      <v-card width="30%" class="profile-teacher ma-5">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-form ref="form" lazy-validation class="pa-3" v-if="profile">
          <section>
            <small>Nama Lengkap</small>
            <p>{{ profile.nama_lengkap }}</p>
          </section>
          <section>
            <small>Nama Pengguna</small>
            <p>{{ profile.name }}</p>
          </section>
          <v-text-field
            v-model="profile.email"
            :counter="100"
            label="Alamat"
            required
          ></v-text-field>

          <v-card-actions>
            <v-btn color="indigo" dark class="mr-1" @click="updatePeserta">
              resend email
            </v-btn>
            <v-btn color="error" class="mr-1" @click="close"> Batal </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import addTeacher from "../../components/Admin/Modal/addTeacher";
import Swal from "sweetalert2";
import {
  DELET_TEACHER,
  GET_TEACHER,
  EDIT_TEACHER
} from "../../graphql/Teacher";

export default {
  name: "listTeacher",

  // apollo query
  apollo: {
    scopeTeacher: {
      query: GET_TEACHER,
      variables() {
        return {
          page: this.page,
          first: this.limit,
          search: this.search
        };
      },
      error(err) {
        console.log(err);
      }
    }
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.scopeTeacher.loading;
    },
    paginate() {
      let page = null;
      if (this.scopeTeacher.paginatorInfo) {
        let total = this.scopeTeacher.paginatorInfo.total;
        page = Math.ceil(total / this.limit);
      }
      return page;
    }
  },
  components: {
    addTeacher
  },
  data() {
    return {
      name: "",
      search: "",
      profile: [],
      page: 1,
      limit: 10,
      headers: [
        { text: "id", value: "id" },
        { text: "Nama Pengguna", value: "name" },
        {
          text: "Nama Lengkap",
          align: "start",
          sortable: false,
          value: "nama_lengkap"
        },
        { text: "Email", value: "email" },
        { text: "Aksi", value: "actions" }
      ]
    };
  },
  methods: {
    editPage(item) {
      this.profile = item;
      document.querySelector(".profile-teacher").style.display = "block";
      document.querySelector(".temp-table").style.width = "70%";
    },
    updatePeserta() {
      const id = parseInt(this.profile.id);
      const id_profile = parseInt(localStorage.getItem("id"));
      this.$apollo
        .mutate({
          mutation: EDIT_TEACHER,
          variables: {
            id: id,
            nama_lengkap: this.profile.nama_lengkap,
            email: this.profile.email,
            id_profile: id_profile,
            connect: this.profile.id
          }
        })
        .then(() => {
          this.$apollo.queries.scopeTeacher.refetch();
          this.$store.dispatch("resendEmail", {
            id: parseInt(this.profile.id)
          });
          this.close();
          Swal.fire({
            icon: "success",
            title: "Profile Widyaiswara telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        });
    },
    deleteConfirm(item) {
      Swal.fire({
        title: "Yakin hapus widyaiswara",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELET_TEACHER,
              variables: {
                id: item.id
              }
            })
            .then(() => {
              this.$apollo.queries.scopeTeacher.refetch();
              Swal.fire({
                icon: "success",
                title: "Widyaiswara telah terhapus",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      document.querySelector(".profile-teacher").style.display = "none";
      document.querySelector(".temp-table").style.width = "100%";
    }
  }
};
</script>

<style scoped>
.profile-teacher {
  display: none;
}
.temp-table {
  width: 100%;
}
</style>
