<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" dark color="indigo">
          <v-icon dark> mdi-plus </v-icon> Tambah Widyaiswara
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Widyaiswara</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama Lengkap"
                  required
                  v-model="nama_lengkap"
                  :error-messages="fullNameErr"
                  @input="$v.nama_lengkap.$touch()"
                  @blur="$v.nama_lengkap.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Username"
                  required
                  v-model="nama"
                  :error-messages="nameErr"
                  @input="$v.nama.$touch()"
                  @blur="$v.nama.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  required
                  v-model="email"
                  :error-messages="emailErr"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :error-messages="passwordErr"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  required
                  label="Kata sandi"
                  counter
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Keluar
          </v-btn>
          <v-btn color="indigo" dark @click="addPeserta">
            <span v-if="loading"> Memuat </span>
            <span v-else> Tambah </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { GET_TEACHER, ADD_TEACHER } from "../../../graphql/Teacher";

export default {
  // apollo query
  apollo: {
    scopeTeacher: {
      query: GET_TEACHER,
      variables() {
        return {
          page: 1,
          first: 10
        };
      }
    }
  },

  //validation start
  mixins: [validationMixin],
  validations: {
    nama_lengkap: { required },
    nama: { required },
    email: { required },
    password: { required, minLength: minLength(8) }
  },
  computed: {
    fullNameErr() {
      const errors = [];
      if (!this.$v.nama_lengkap.$dirty) return errors;
      !this.$v.nama_lengkap.required &&
        errors.push("Nama lengkap tidak boleh kosong!");
      return errors;
    },
    nameErr() {
      const errors = [];
      if (!this.$v.nama.$dirty) return errors;
      !this.$v.nama.required && errors.push("Username tidak boleh kosong!");
      return errors;
    },
    emailErr() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email tidak boleh kosong!");
      return errors;
    },
    passwordErr() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("Kata sandi tidak boleh kosong!");
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      return errors;
    }
    // config error vuelidate end ---------------------
  },
  data: () => ({
    id: null,
    nama: "",
    nama_lengkap: "",
    email: "",
    password: "",
    dialog: false,
    show: false,
    rules: [
      value =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!"
    ],
    loading: false
  }),
  methods: {
    addPeserta() {
      this.$v.nama_lengkap.$touch();
      this.$v.nama.$touch();
      this.$v.email.$touch();
      this.$v.password.$touch();
      if (!this.$v.nama_lengkap.required && !this.$v.nama.required) {
        console.log("datanya kosong cuy");
        this.loading = false;
        return;
      } else if (!this.$v.email.required && !this.$v.password.required) {
        console.log("ini juga datanya kosong");
        this.loading = false;
        return;
      } else {
        this.loading = true;
        const name = this.nama;
        const nama_lengkap = this.nama_lengkap;
        const email = this.email;
        const password = this.password;
        const role_id = "TEACHER";
        const created_by = parseInt(localStorage.getItem("id"));
        this.$apollo
          .mutate({
            mutation: ADD_TEACHER,
            variables: {
              name: name,
              nama_lengkap: nama_lengkap,
              email: email,
              password: password,
              password_no_hash: password,
              role_id: role_id,
              created_by: created_by
            }
          })
          .then(() => {
            this.nama = "";
            this.email = "";
            this.nama_lengkap = "";
            this.password = "";
            this.dialog = false;
            this.loading = false;
            this.$apollo.queries.scopeTeacher.refetch();
            Swal.fire({
              icon: "success",
              title: "Anda telah menambahkan widyaiswara",
              showConfirmButton: false,
              timer: 3000,
              toast: true
            });
          }).catch((err) => {
            this.loading = false;
            console.log(err)
          })
      }
    }
  }
};
</script>

<style></style>
